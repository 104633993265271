import { Link } from 'gatsby'
import { getImage, StaticImage } from 'gatsby-plugin-image'

import videoRingsImage from '~/assets/images/case-study/tradeaboat/video-review-rings.svg?file'
import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import LazyHydrate from '~/components/shared/LazyHydrate'
import { CommonImageProps } from '~/types/common-props'
import { fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyClientVideoReview from '~/views/CaseStudies/components/CaseStudyClientVideoReview'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'
import CaseStudyVideoCase from '~/views/CaseStudies/components/CaseStudyVideo'

import * as containerStyles from './Tradeaboat.module.scss'
import useTradeaboatStaticQuery from './useTradeaboatStaticQuery'

const imageProps: CommonImageProps = {
  width: 80,
  height: 80,
}

const quotes = {
  client: {
    name: 'Celso Prado',
    position: 'Digital General Manager at the Adventures Group',
  },
  fe: {
    name: 'Eugene',
    position: 'Tech Lead & React expert at Codica',
  },
}

const previewAfricarTitle = 'Multi-Vendor Vehicle Marketplace'
const previewIxTitle = 'Online Travel Marketplace'

const CaseStudiesTradeaboat = () => {
  const query = useTradeaboatStaticQuery()

  const bannerCase = getImage(fileToImageLikeData(query.bannerCase))
  const clientVideoReview = getImage(
    fileToImageLikeData(query.clientVideoReview),
  )
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFour = getImage(
    fileToImageLikeData(query.solutionDeliveredFour),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  const solutionDeliveredSix = getImage(
    fileToImageLikeData(query.solutionDeliveredSix),
  )
  const solutionDeliveredSeven = getImage(
    fileToImageLikeData(query.solutionDeliveredSeven),
  )
  const solutionDeliveredEight = getImage(
    fileToImageLikeData(query.solutionDeliveredEight),
  )
  const solutionDeliveredNine = getImage(
    fileToImageLikeData(query.solutionDeliveredNine),
  )
  const videoCaseImage = getImage(fileToImageLikeData(query.videoCaseImage))

  return (
    <MainLayout isTransparent scrollTop>
      <div className={containerStyles.caseStudyHead}>
        <CaseStudyHead
          title="Online marketplace platform in Australia for Tradeaboat"
          subtitle="Learn how Codica delivered an online marketplace for boats, redesigned the platform to look fresh, made it mobile-friendly, and maintained integrations with third-party providers and systems."
          image={bannerCase}
          classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
          imageAlt="Marketplace for boats Tradeaboat"
          imageTitle="Boat sales marketplace Tradeaboat"
          domain="Boating"
          location="Australia"
          timeline="August 2020 - February 2021"
          services={
            <>
              <Link to="/services/product-discovery/" key="productKey">
                Product discovery
              </Link>
              , Front-end and Back-end development,
              <Link
                key="designKey"
                to="/services/ui-ux-design/"
                className="ml5"
              >
                UX/UI design
              </Link>
              ,
              <Link
                to="/services/online-marketplace-development/"
                className="ml5"
                key="marketplaceDevkey"
              >
                Online marketplace development
              </Link>
              ,
              <Link
                to="/services/progressive-web-apps-development/"
                className="ml5"
                key="pwaDevKey"
              >
                PWA development
              </Link>
            </>
          }
          team="3 Software Developers, 1 UX/UI Designer, 1 QA Engineer, 1 Project Manager, 1 DevOps Engineer"
          technologies="Ruby on Rails, React JS, PostgreSQL, AWS"
        />
      </div>

      <div className={containerStyles.caseStudyVideoReview}>
        <LazyHydrate whenVisible>
          <CaseStudyClientVideoReview
            color="videoReviewTradeaboat"
            link="https://www.youtube.com/embed/K9aOABDeKPI"
            clientImage={clientVideoReview}
            imageTitle="Customer review about boat sales marketplace project"
            name="Celso Prado, Digital General Manager at the Adventures Group"
            review={[
              {
                item: '“One of the main projects I took on when accepting this role was to redevelop two of the business’s various websites. The Codica team seemed to have a clear process from the beginning. The initial sales contact was professional, and we got a real feel for the company when we involved some developers in the conversation.',
              },
              {
                item: 'We are in verticals where market leaders have hundreds of in-house developers and multi-billion dollars in annual revenue. And despite all of that, our lean team, with the help from Codica, is growing its market share year after year. They are like my secret weapon.”',
              },
            ]}
            bgRings
            bgRingsClass={containerStyles.caseStudyVideoReview__bgRing}
            bgRingImage={videoRingsImage}
          />
        </LazyHydrate>
      </div>

      <CaseStudyBackground
        backgroundColor={containerStyles.caseStudy__bgDark}
        lineColor={containerStyles.caseStudyBackground__lineColor}
        bgTextLeft={[
          'Trade A Boat is Australia’s favorite online platform to sell and buy new or used boats, and marine equipment. Users can also find entertaining and informative content on this boat selling website.',
          <div className="mb-3" key="bgTextLeft" />,
          'In addition, private sellers and dealers have the opportunity to publish and promote their advertisements on Trade A Boat Magazine.',
          <div className="mb-3" key="bgTextRight" />,
          'Throughout more than 40 years, Trade A Boat Magazine has been providing the latest news, reviews, and tips from boating experts to those who love the marine world.',
        ]}
        bgTextRight={[
          'The customer wanted us to refine the existing solution, and create a new website to reach better business results, increase the number of private listings submissions and generate more leads to advertisers. The main requirement also was to make this popular boat selling website in Australia mobile-friendly and fresh-looking.',
        ]}
        sectionBusiness={[
          {
            label:
              'Redesign the previous platform and create a new platform for the marketplace for boats, which would be visually-appealing and mobile-friendly.',
          },
          {
            label:
              'Increase private listings submissions and the number of leads generated to advertisers.',
          },
          {
            label:
              'Maintain third-party integrations with service providers who provide content and dealer inventory management systems.',
          },
        ]}
        sectionValueDelivered={[
          {
            label:
              'Built a modern, robust and fast loading web solution. This positively influenced the platform’s ranking, increasing the number of user visits.',
          },

          {
            label:
              'Created a Progressive Web App which improved user experience on mobile devices, and enabled Trade A Boat to receive more conversions.',
          },

          {
            label:
              'Arranged valid synchronization of the data between the marketplace for boats and CDS platform to connect various dealers CMS/CRM inventory management systems. This way, the platform handles advertisements all over Australia.',
          },

          {
            label:
              'Customized the function of choosing different packages to promote advertisements and increase sales for private sellers and dealers.',
          },

          {
            label:
              'Created a new UI and UX design for the boat sales website, to make it user-friendly, which led to increasing users’ involvement and engagement.',
          },
        ]}
      />

      <div className={containerStyles.caseStudyQuote__bgLight}>
        <CaseStudyQuote
          quote={[
            {
              text: '“When working on this project, I got an interesting task to implement two websites, Trade A Boat and TradeRVs, similar in design and functionality but different in content in one repository. To fulfill this objective, we created a mechanism that allowed easily adding and changing content depending on the environment variable.',
            },
            {
              text: 'It was also an exciting task to implement these websites from scratch and at the same time maintain SEO optimization of existing web solutions. In general, the project gave me vast experience in implementing complex non-trivial tasks, SEO optimization, and application security.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/FE-Eugene.png"
              alt={`${quotes.fe.name}, ${quotes.fe.position} at Codica`}
              title={`${quotes.fe.name}, ${quotes.fe.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.fe.name}
          position={quotes.fe.position}
        />
      </div>

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudy__bgDark}
        title="Do you need an experienced team to build a custom web platform?"
        description="We apply best practices to help you save costs and launch quickly."
        nameButton="Talk to an expert"
        linkButton="/contacts/"
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudySolutionHead title="Solution delivered" />
        <CaseStudySolution
          isImageSmall
          image={solutionDeliveredOne}
          title="User- and mobile-friendly platform"
          description={[
            'Our team delivered a progressive web application, which provides an improved user experience. It works like a mobile application, but users don’t need to install or update it.',
            <div className="mb-2" key="descr" />,
            'It is possible to use PWA on the online marketplace for boats via any mobile devices, because this technology is supported by all operating systems. Moreover, PWAs are known to help improve SEO rankings.',
          ]}
          alt="PWA for Tradeaboat marketplace"
          imageTitle="PWA implementation for Tradeaboat"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredTwo}
          title="Website optimization for search engines"
          description={[
            'We conducted complex search engine optimization (SEO) to make the online marketplace for boats in Australia noticeable on the web and user-friendly.',
            <div className="mb-2" key="descrOne" />,
            'We have improved the website’s Core Web Vitals indicators, which measure websites’ speed, responsiveness, and visual stability, offering more opportunities for a rankings boost.',
            <div className="mb-2" key="descrTwo" />,
            'Our work on optimisation also included adding micro-markup data to the web pages, and transition to the new website version, with redirects to the most important pages due to the changes in structure.',
          ]}
          alt="Core Web Vitals indicators for boat marketplace"
          imageTitle="Tradeaboat marketplace optimization"
        />
        <CaseStudySolution
          image={solutionDeliveredThree}
          title="Third-party services integration"
          description={[
            'Integration with third-party services such as the CDS platform allows the Trade A Boat website to receive advertisements from dealers throughout Australia.',
            <div className="mb-2" key="descr" />,
            'Thanks to this custom marketplace development, the boat marketplace expanded the audience, and the number of listings.',
          ]}
          alt="Third-party integrations for Tradeaboat"
          imageTitle="Third-parties for boat selling website"
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredFour}
          title="User-friendly search"
          description={[
            'In terms of UX design, we have improved search filters and made search more structured, intuitive and user-friendly.',
            <div className="mb-2" key="descr" />,
            'New optimized site search brings users to the services, content, and products they’re looking for in less time. So, it helps to encourage users to engage, convert, and make a purchase.',
          ]}
          alt="Convenient search for Tradeaboat"
          imageTitle="Improved search for boat selling website"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredFive}
          title="Convenient product card"
          description={[
            'On a card, users can see all the information about the product, such as category, product condition, location, etc. Plus, we made an icon for each category on the site.',
            <div className="mb-2" key="descr" />,
            'Thus, we enhanced the effectiveness of communicating with visitors. Icons increase readability, highlight important content, and improve UX design.',
          ]}
          alt="Product card for Tradeaboat website"
          imageTitle="Product card for boat selling website"
        />
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredSix}
          title="Simple ad creation"
          description="Also, we have simplified the process of adding product listings to the site, divided it into steps. To create an ad, the seller fills in all information about the product, adds a description, then a photo, etc."
          alt="Ads creation for Tradeaboat"
          imageTitle="Simple listings creation for Tradeaboat"
        />
      </div>

      <div className={containerStyles.caseStudy__bgLightReverse}>
        <CaseStudySolution
          image={solutionDeliveredSeven}
          title="Promotional packages for sellers"
          description={[
            'We added the selection of advertising packages for private sellers and dealers to help them promote the advertisements, attract more buyers, and increase online boat sales.',
            <div className="mb-2" key="descr" />,
            'When creating the listing, vendors and dealers can get additional opportunities by choosing a suitable package. For example, they can receive unlimited listings and updates. Also, their advertisements can appear higher in search results for a particular time, etc.',
          ]}
          alt="Promotional packages for sellers"
          imageTitle="Promotional packages for private sellers and dealers"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredEight}
          title="Dashboard for advertisers"
          description={[
            'We made a clean-looking dashboard to demonstrate listing performance to advertisers. In such a way, those who look at the dashboard will easily understand the collected data about their listings, including views, inquiries from users, and the sales number.',
            <div className="mb-2" key="descr" />,
            'Also, we provided the option for dealers to subscribe for monthly newsletter and receive statistics about their listings.',
          ]}
          alt="Dashboard for boat selling website"
          imageTitle="Clean dashboard for boat marketplace"
        />
        <CaseStudySolution
          image={solutionDeliveredNine}
          title="UI redesign"
          description={[
            'Based on the logo and theme of the site, turquoise was chosen as the main color. Since it’s bright enough, we chose blue and beige for the secondary colors to balance it.',
            <div className="mb-2" key="descr" />,
            'We used photographs of boats and the sea for banners and separate blocks because they dilute the text well and seem appealing. The new look of the online marketplace for boats helped our client improve customer satisfaction and increase user engagement.',
          ]}
          alt="UI redesign of boat selling website"
          imageTitle="UI redesign of Tradeaboat online marketplace"
        />
      </div>

      <LazyHydrate whenVisible>
        <CaseStudyVideoCase
          title="How it works"
          description="This video shows you how the boat selling website works. Users can not only sell or buy boats and marine equipment but easily find everything they need to know about the marine industry, from the whys to the hows to the whats."
          ringsClass={containerStyles.caseStudyVideo__ring}
          image={videoCaseImage}
          videoLink="https://www.youtube.com/embed/l2ilWhR3WqI"
        />
      </LazyHydrate>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudyTech
          technologies={[
            { label: 'Ruby on Rails', icon: 'ror' },
            { label: 'PostgreSQL', icon: 'sql' },
            { label: 'AWS', icon: 'aws' },
            { label: 'React JS', icon: 'react' },
            { label: 'Next.js', icon: 'nextjs' },
          ]}
          integrations={[
            { label: 'Stripe', icon: 'stripe' },
            { label: 'CDS', icon: 'cds' },
            { label: 'HemaX', icon: 'hemax' },
            { label: 'Gitlab', icon: 'gitlab' },
            { label: 'Sentry', icon: 'sentry' },
            { label: 'HubSpot', icon: 'hubspot' },
          ]}
        />
      </div>

      <CaseStudyQuote
        isBottom
        quote={[
          {
            text: '“During the work on the project, Codica’s experts showed great communication skills and a high level of professionalism. They performed the work with high quality, taking into account all our requirements, and delivered the solution within the agreed time frame.”',
          },
        ]}
        photo={
          <StaticImage
            src="../../../../assets/images/avatars/clients/client-tradervs.png"
            alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
            title={`${quotes.client.name}, ${quotes.client.position}`}
            {...imageProps}
          />
        }
        bgRing={containerStyles.caseStudyQuote__bgRing}
        name={quotes.client.name}
        position={quotes.client.position}
      />

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s turn your idea into a successful product!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewAfricarTitle}
              color="africarColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/africar.png"
                  alt={`Case study: ${previewAfricarTitle} | Codica`}
                  title={`Case study: ${previewAfricarTitle}`}
                  placeholder="blurred"
                  objectFit="fill"
                  width={350}
                />
              }
              url="multi-vendor-vehicle-marketplace"
            />
            <CaseStudyPreview
              title={previewIxTitle}
              color="ixColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/iexpedition.png"
                  alt={`Case study: ${previewIxTitle} | Codica`}
                  title={`Case study: ${previewIxTitle}`}
                  placeholder="blurred"
                  objectFit="fill"
                  width={350}
                />
              }
              url="travel-management-platform"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesTradeaboat
