// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bH_kw";
export var caseStudyBackground__lineColor = "bH_kt";
export var caseStudyHead = "bH_kn";
export var caseStudyHead__imageWrapper = "bH_kp";
export var caseStudyProjectsSection = "bH_kx";
export var caseStudyQuote__bgLight = "bH_kD";
export var caseStudyQuote__bgRing = "bH_kr";
export var caseStudyVideoReview = "bH_l7";
export var caseStudyVideoReview__bgRing = "bH_l8";
export var caseStudyVideo__ring = "bH_kG";
export var caseStudy__bgDark = "bH_km";
export var caseStudy__bgLight = "bH_kl";
export var caseStudy__bgLightReverse = "bH_l6";